import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";

import './style.scss';

import ItemBar from '../ItemBar'

const ItemLink = props => {
  const { link } = props

  return(
      <Link className="item-link" to={link}>
        <ItemBar {...props}/>
      </Link>
  )
}

ItemLink.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  icon: PropTypes.object,
  link: PropTypes.string.isRequired,
}

ItemLink.defaultProps = {
  title: '',
  text: '',
  icon: null,
  link: '',
}

export default ItemLink