import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Home from './pages/Home'
import Category from './pages/Category'
import Menu from './pages/Menu'
import Info from './pages/Info'

import Loading from './components/Loading'

import { fetchMenu } from './actions/menuActions';

class Routes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.props.fetchMenu()

    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500)
  }

  render() {
    if (this.state.loading) {
      return <Loading />
    }
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/:mainMenuId/category" component={Category} />
          <Route exact path="/:mainMenuId/category/:categoryId/menu" component={Menu} />
          <Route exact path="/:mainMenuId/menu" component={Menu} />
          <Route exact path="/info" component={Info} />
        </Switch>
      </Router>
    )
  }
}

Routes.propTypes = {
  fetchMenu: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {}
}

// export default Routes
export default connect(
  mapStateToProps,
  {
    fetchMenu
  }
)(Routes)