import React, {Component} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import './style.scss';

class Loading extends Component {
    render() {
        return (
            <div className="loading-view">
                <img src="./images/logo.png" className="app-logo" alt="logo" />

                <div className="loading-text">
                    <FontAwesomeIcon className="fa-pulse" icon={faSpinner} />
                    Loading...
                </div>
            </div>
        )
    }
}

export default Loading