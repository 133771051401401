import React, {Component} from 'react'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faSearch, faHome, faFrown } from '@fortawesome/free-solid-svg-icons'

import './style.scss'

import TopBar from '../../components/TopBar'
import ItemBar from '../../components/ItemBar'

class Menu extends Component {
  constructor(props){
    super(props);

    this.state = {
      searchText: ''
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (event) {
    this.setState({ [event.target.name] : event.target.value })
  }

  getMainMenuById(mainMenuId) {
    const menuData = this.props.menu
    return menuData.find(m => Number(m.id) === Number(mainMenuId))
  }

  getCategotyById(category, categoryId) {
    return category.find(m => Number(m.id) === Number(categoryId))
  }

  renderMenuItems(menu) {
    const { searchText } = this.state
    if (searchText.length > 2) {
      menu = menu.filter(m => m.name.toLowerCase().includes(searchText.toLowerCase()))
    }

    if (menu.length) {
      return menu.map(data => {
        return (
          <ItemBar
            key = {`mainMenu_${data.id}`}
            title = {data.name}
            text = {data.description}
            price = {data.price}
            subMenu = {data.submenu}
          />
        )
      })
    } else {
      return (
        <div className="not-found">
          <FontAwesomeIcon className="search-icon" icon={faFrown} />
          No Menu Items Found.
        </div>
      )
    }
  }

  renderFilterBox() {
    return (
      <div className="filter-box">
        <input type="text" name="searchText" value={this.state.searchText} onChange={event => this.handleChange(event)} />
        <FontAwesomeIcon className="search-icon" icon={faSearch} />
      </div>
    )
  }

  render() {
    const mainMenuId = this.props.match.params.mainMenuId
    const categoryId = this.props.match.params.categoryId

    let mainMenu
    let menuData
    let subTitle = ''
    let lefIconlink = '/'
    let rightIcon = ''
    let rightIconlink = null

    if (categoryId) {
      mainMenu = this.getMainMenuById(mainMenuId)
      menuData = this.getCategotyById(mainMenu.category, categoryId)
      subTitle = mainMenu.name
      lefIconlink = `/${mainMenuId}/category`
      rightIcon = faHome
      rightIconlink = '/'
    } else {
      menuData = this.getMainMenuById(mainMenuId)
    }

    return (
      <div className="Menu">
        <TopBar
          subTitle = {subTitle}
          title = {menuData.name}
          leftIcon = {faArrowLeft}
          lefIconlink = {lefIconlink}
          rightIcon = {rightIcon}
          rightIconlink = {rightIconlink}
        />
        <div className="body-section">
          {this.renderFilterBox()}
          <div className="main-container">
            <div className="container">
              {this.renderMenuItems(menuData.menu)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    menu: state.menu.data
  }
}

// export default Menu
export default connect(
  mapStateToProps,
  {}
)(Menu)