import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import rootReducer from './reducers'

const initialState = {}

const getMiddlewares = () => {
  if (process.env.ENVIRONMENT === 'local') {
    return [thunk, logger]
  }

  return [thunk]
}

var composeVar
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  composeVar = compose(
    applyMiddleware(...getMiddlewares()),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
} else {
  composeVar = compose(
    applyMiddleware(...getMiddlewares())
  )
}

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};
const pReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
  pReducer,
  initialState,
  composeVar
)

export const persistor = persistStore(store)
