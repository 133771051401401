import { FETCH_MENU } from './types'

export const fetchMenu = () => dispatch => {
  fetch('api/menu.json')
  .then(res => res.json())
  .then(data =>
    dispatch({
      type: FETCH_MENU,
      payload: data.data
    })
  )
}