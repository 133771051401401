import React, {Component} from 'react'
import { connect } from 'react-redux'
import {Link} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faMobile, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import './style.scss'

import ItemLink from '../../components/ItemLink'

class Home extends Component {
  getMainCategories() {
    const menuData = this.props.menu
    return menuData.map(data => {
      let link = '/'
      if (data.category) {
        link = `/${data.id}/category`
      } else {
        if (data.menu) {
          link = `/${data.id}/menu`
        }
      }

      return (
        <ItemLink
          key = {`mainMenu_${data.id}`}
          title = {data.name}
          text = {data.description}
          icon = {faArrowRight}
          link = {link}
        />
      )
    })
  }

  render() {
    return (
      <div className="Home">
        <header>
          <a className="call" href="tel:+61 03 5356 4234">
            <FontAwesomeIcon icon={faMobile} />
          </a>
          <Link className="info" to="/info">
            <FontAwesomeIcon icon={faInfoCircle} />
          </Link>
          <div className="container">
            <img src="./images/logo.png" className="app-logo" alt="logo" />
          </div>
        </header>
        <div className="body-section">
          <div className="page-title">Menu</div>
          <div className="main-container">
            <div className="menu-items">
              {this.getMainCategories()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    menu: state.menu.data
  }
}

// export default Home
export default connect(
  mapStateToProps,
  {}
)(Home)
