import { FETCH_MENU } from '../actions/types'

const initialState = {
  data: [],
};

export default function(state = initialState, {type, payload}) {
  switch (type) {
    case FETCH_MENU:
      return {
        ...state,
        data: payload
      };
    default:
      return state;
  }
}