import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.scss';

const TopBar = props => {
  const {
    subTitle,
    title,
    leftIcon,
    rightIcon,
    lefIconlink,
    rightIconlink
  } = props

  return(
    <header>
      <div className="container">
        <div className="left-icon">
          <Link className="item" to={lefIconlink}>
            <FontAwesomeIcon icon={leftIcon} />
          </Link>
        </div>
        <div className="title">
          {(subTitle) && <small>{subTitle}</small>}
          {title}
        </div>
        <div className="right-icon">
          {
            (rightIcon) && 
            (<Link className="item" to={rightIconlink}>
              <FontAwesomeIcon icon={rightIcon} />
            </Link>)
          }
        </div>
      </div>
    </header>
  )
}

TopBar.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  leftIcon: PropTypes.object.isRequired,
  rightIcon: PropTypes.any,
  lefIconlink: PropTypes.string.isRequired,
  rightIconlink: PropTypes.string
}

TopBar.defaultProps = {
  subTitle: '',
  title: '',
  leftIcon: null,
  rightIcon: null,
  lefIconlink: '',
  rightIconlink: ''
}

export default TopBar