import React, {Component} from 'react'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft, faFrown } from '@fortawesome/free-solid-svg-icons'

import './style.scss'

import TopBar from '../../components/TopBar'
import ItemLink from '../../components/ItemLink'

class Menu extends Component {
  getMenuById(id) {
    const menuData = this.props.menu
    return menuData.find(m => Number(m.id) === Number(id))
  }

  renderCategoriesItems(category) {
    if (category.length) {
      return category.map(data => {
        const mainMenuId = this.props.match.params.mainMenuId
        const link = `/${mainMenuId}/category/${data.id}/menu`

        return (
          <ItemLink
            key = {`category_${data.id}`}
            title = {data.name}
            icon = {faArrowRight}
            subMenu = {data.submenu}
            link = {link}
          />
        )
      })
    } else {
      return (
        <div className="not-found">
          <FontAwesomeIcon className="search-icon" icon={faFrown} />
          No Menu Items Found.
        </div>
      )
    }
  }

  render() {
    const mainMenuId = this.props.match.params.mainMenuId
    const menuData = this.getMenuById(mainMenuId)

    return (
      <div className="Menu Category">
        <TopBar
          title = {menuData.name}
          leftIcon = {faArrowLeft}
          lefIconlink = '/'
        />
        <div className="body-section">
          <div className="space-box"></div>
          <div className="main-container">
            <div className="container">
              {this.renderCategoriesItems(menuData.category)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    menu: state.menu.data
  }
}

// export default Menu
export default connect(
  mapStateToProps,
  {}
)(Menu)