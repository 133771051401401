import React, {Component} from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.scss';

class ItemLink extends Component {
  renderSubMenus(menu) {
    if (menu.length) {
      return menu.map(data => {
        return (
          <li key={`subMenu_${data.id}`}>
            <div className="content">
              <div className="title">{data.name}</div>
              <div className="text">{data.description}</div>
            </div>
            {
              (data.price) && (
                <div className="prices">
                  {
                    (data.price.gls) && <div>G:{data.price.gls}</div>
                  }
                  {
                    (data.price.btl) && <div>B:{data.price.btl}</div>
                  }
                </div>
              )
            }
          </li>
        )
      })
    }
  }

  render() {
    const {
      title,
      text,
      icon,
      price,
      subMenu
    } = this.props

    return(
      <div className="item-bar">
        <div className="row">
          <div className="content">
            <div className="title">{title}</div>
            {
              (text) && <div className="text">{text}</div>
            }
          </div>
          {
            (icon) && 
            (
            <div className="icon">
              <FontAwesomeIcon icon={icon} />
            </div>
            )
          }
          {
            (price) && 
            (
            <div className="price">{price}</div>
            )
          }
        </div>
        {
          (subMenu) && 
          (
            <div className="row">
              <div className="sub-menu">
                <ul>
                  {this.renderSubMenus(subMenu)}
                </ul>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

ItemLink.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  icon: PropTypes.object,
  price: PropTypes.string,
  subMenu: PropTypes.array
}

ItemLink.defaultProps = {
  title: '',
  text: '',
  icon: null,
  price: null,
  subMenu: null
}

export default ItemLink