import React, {Component} from 'react'

import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons'

import './style.scss'

import TopBar from '../../components/TopBar'

class Info extends Component {
  constructor(props) {
    super(props);

    this.state = {
      appInfo: null,
      companyInfo: null,
      loading: true
    };
  }

  async componentDidMount() {
    const appInfo = await this.fetchAppInfo()
    const companyInfo = await this.fetchAppCompanyInfo()
    const loading = false

    this.setState({
      appInfo, companyInfo, loading
    })
  }

  async fetchAppInfo() {
    try {
      const response = await axios.get('https://qrmenu.mindshost.com/api/info.php');
      console.log(response);
      return response.data
    } catch (error) {
      console.error(error);
    }
  }

  async fetchAppCompanyInfo() {
    try {
      const response = await axios.get('https://qrmenu.mindshost.com/api/company.php');
      console.log(response);
      return response.data
    } catch (error) {
      console.error(error);
    }
  }

  renderAppInfo() {
    const { appInfo, companyInfo } = this.state

    return (
      <div className="container">
        <div dangerouslySetInnerHTML={{__html: appInfo.description}} />
        <hr/>
        <h1>Company Info</h1>
        <strong>{companyInfo.name}</strong><br/>
        {companyInfo.address}<br/>
        Phone: <a href={`tel:${companyInfo.phone}`}>{companyInfo.phone}</a> <br/>
        Email: <a href={`mailto:${companyInfo.email}`}>{companyInfo.email}</a> <br/>
        Website: <a href={companyInfo.website} target="_blank" rel="noopener noreferrer">{companyInfo.website}</a>
        <hr/>
        <div className="copyright">{companyInfo.copyright}</div>
      </div>
    )
  }

  renderLoading() {
    return (
      <div className="loading-text">
        <FontAwesomeIcon className="fa-pulse" icon={faSpinner} />
        Loading App Info...
      </div>
    )
  }

  render() {
    const { loading } = this.state
    return (
      <div className="Info-View">
        <TopBar
          title = "App Info"
          leftIcon = {faArrowLeft}
        />
        <div className="body-section">
          <div className="space-box"></div>
          <div className="main-container">
            {
              loading ? this.renderLoading() : this.renderAppInfo()
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Info